import React from "react";

import { NewIntegrationForm } from "../../components/marketplace/NewIntegrationForm";
import { HASURA_HUB_URL } from "../../utils/constants";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const ogImage = {
  ogImage:
    "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675861503/main-web/OG_image_2_1_chijpv.jpg",
};

const canonicalUrl = `https://hasura.io${HASURA_HUB_URL}submit-new-integration/`;

const SubmitNewIntegration = props => {
  return (
    <Layout location={props.location}>
      <Seo
        title="Submit a new integration | Hasura Hub"
        description="Submit a new integration, Hasura Hub is a community-contributed list of examples of database schemas, GraphQL APIs, and REST APIs that can be connected to
          Hasura. Deploy them with our new One Click Deploy to Hasura Cloud feature.."
        meta={ogImage} // might need 4 diff ogimages set in context
        canonicalLink={canonicalUrl}
      />
      <NewIntegrationForm />
    </Layout>
  );
};

export default SubmitNewIntegration;
