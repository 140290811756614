import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { COLORS, ELEVATION } from "../../globals/designSystem";
import { transition } from "./CommonStyled";
export const StyledSectionSubNavWrapper = styled.section`
  padding: 16px 0;
  background-color: ${COLORS.white};
  box-shadow: ${ELEVATION.l_top_0};
`;

export const StyledSectionSubNavWrapperStickey = styled(StyledSectionSubNavWrapper)`
  align-self: flex-start;
  position: sticky;
  top: 57px;
  z-index: 9;
  @media(max-width: 767px) {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    /* min-height: 64px; */
  }
`;

export const StyledSubNavWrapper = styled.div`
  display: flex;
  align-items: center;
  .flexOne {
    flex: 1;
    position: relative;
    .subNavFlex {
      display: flex;
      align-items: center;
      a {
        color: ${COLORS.neutral_1000};
        &:hover {
          color: ${COLORS.blue_600};
        }
      }
      .slash {
        margin: 0 6px;
      }
      .sky_80 {
        color: ${COLORS.blue_600};
      }
    }
    .displayInline {
      display: inline-block;
      .displayFlex {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          margin-left: 8px;
          ${transition};
        }
        .rotateImg {
          transform: rotate(90deg);
        }
      }
    }
    .nevItemActive {
      a {
        color: ${COLORS.sky_80};
      }
    }
    .displayNone {
      display: none;
    }
  }

  .hideMobileView {
    display: flex;
  }
  .tagMenuBtnHide {
    display: none;
  }
  .tagMenuBtnShow {
    display: block;
  }
  .showMobileView {
    display: none;
  }
  .buttonWrapper {
    padding-left: 40px;
  }
  @media(max-width: 800px) {
    display: block;
    /* .flexOne {
      display: none;
    } */
    .hideMobileView {
      display: none;
    }
    .showMobileView {
      display: block;
    }
    .flexOne {
      .subNavFlex {
        display: block;
      }
    }
    .subNavWrapper {
      display: flex;
      align-items: center;
      .flexOneMobile {
        /* padding: 13px 0; */
        flex: 1;
      }
      a {
        flex: 1;
      }
      svg {
        transition: all .2s ease;
      }
      .rotateImg {
        transform: rotate(90deg);
      }
    }
    .dataHubSubNavWrapper {
      padding-bottom: 16px;
      border-bottom: 1px solid ${COLORS.grey_30};
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      padding: 16px 32px;
      padding-top: 0;
    }
  }
`;

export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  .mr0 {
    margin-right: 0;
  }
  .tabNavItemActive {
    color: ${COLORS.sky_80};
  }
  @media(max-width: 767px) {
    display: block;
    .buttonWrapper {
      padding-left: 0px;
      padding: 16px 0;
    }
  }
 `;
export const StyledNavItem = styled.a`
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${COLORS.grey_100};
  margin: 0 20px;
  display: flex !important;
  align-items: center;
  span {
    flex: 1;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${COLORS.blue_90};
  }
  @media(max-width: 800px) {
    display: block;
    padding: 20px 0;
    &:first-child {
      padding: 6px 0;
    }
    &:nth-child(2) {
      padding-top: 34px;
    }
    margin: 0 0;
  }
`;
export const StyledNavItemLink = styled(props => <Link {...props} />)`
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${COLORS.grey_100};
  margin: 0 20px;
  display: flex !important;
  align-items: center;
  .circle {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${COLORS.grey_100};
    margin-right: 12px;
  }
  .blueCircle {
    background-color: ${COLORS.sky_80};
  }
  span {
    flex: 1;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${COLORS.blue_90};
  }
  @media(max-width: 800px) {
    display: block;
    padding: 16px 0;
    &:first-child {
      padding: 6px 0;
    }
    &:nth-child(2) {
      padding-top: 24px;
    }
    margin: 0 0;
  }
`;
export const StyledNavButton = styled.button`
  background-color: ${COLORS.blue_10};
  border: 1px solid ${COLORS.blue_30};
  border-radius: 4px;
  color: ${COLORS.blue_90};
  height: 32px;
  padding: 5px 12px;
  font-family: "IBM Plex Sans";
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.blue_20};
  }
`;
