import React, { useState } from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

import { scrollToTop } from "../../utils/helpers";
import { HASURA_HUB_URL, isBrowser } from "../../utils/constants";
import { StyledSubNavWrapper } from "../shared/StyledSubNav";
import { StyledDesc3 } from "../shared/StyledTypography";
import { Flex, Box, Heading, Text, Image } from "../../globals/UIKit";
import Paperform from "../contactus/Paperform";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
// import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import { mq } from "../../globals/utils";

const StyledSectionWrapperBg = styled.div`
  padding: 56px 0 100px;
  clear: both;
  ${mq.between("md", "lg")} {
    padding: 70px 0;
  }
  ${mq.below.md} {
    padding: 50px 0;
  }

  background: linear-gradient(180deg, #e3faff 15.65%, #f7f2ff 113.16%);
  /* background: #e6edff; */

  .datahub-form {
    background: transparent;
    min-width: 500px;
    width: 500px;
  }

  @media (min-width: 761px) and (max-width: 1100px) {
    .datahub-form {
      background: transparent;
      min-width: 360px;
      width: 40%;
      margin-left: 30px;
    }
  }

  @media (min-width: 761px) {
    .close-icon {
      display: none;
    }
  }

  @media (max-width: 760px) {
    background: #f0f4ff;

    .desk-form-div {
      width: 100%;
      display: ${props => (props?.isFormActiveOnMobile ? "" : "none")};
      /* display: none; */
    }

    .close-icon {
      margin-left: 15px;
      display: ${props => (props?.isFormActiveOnMobile ? "" : "none")};
    }

    .datahub-form {
      background: transparent;
      min-width: auto;
      width: 100%;
    }

    h1 {
      font-size: 24px;
    }
  }
`;

export const NewIntegrationForm = () => {
  const [isFormActiveOnMobile, toggleFormView] = useState(false);

  const onSubmitCB = () => {
    if (isBrowser) {
      window.localStorage.setItem("hasuraAdsConsent", "true");
    }
  };

  return (
    <StyledSectionWrapperBg isFormActiveOnMobile={isFormActiveOnMobile}>
      <StyledContainerWrapper>
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          css={css`
            @media (max-width: 760px) {
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            }
          `}
        >
          <Box width="100%">
            <StyledSubNavWrapper>
              <div className="flexOne">
                <StyledDesc3 className="subNavFlex">
                  <Link to={HASURA_HUB_URL}>Hasura Hub</Link>
                  <span className="slash">{" / "}</span>
                  <span className="sky_80">Submit a new integration</span>
                </StyledDesc3>
              </div>
            </StyledSubNavWrapper>
            <Box mt="24px" width="100%">
              <Flex justifyContent="space-between" width="100%" mb="28px">
                <Heading
                  fontSize="30px"
                  fontWeight="700"
                  lineHeight="1.2"
                  color="#000615"
                  letterSpacing="letter-spacing: -0.01em"
                >
                  Submit a new integration
                </Heading>
                <Image
                  onClick={() => toggleFormView(false)}
                  src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675976651/main-web/icons/x-close_hprsj6.svg"
                  alt="close-icon"
                  minWidth="24px"
                  width="24px"
                  className="close-icon"
                />
              </Flex>
              {isFormActiveOnMobile ? null : (
                <Box maxWidth="490px">
                  <Flex alignItems="flex-start" mb="24px">
                    <Image
                      src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675711138/main-web/icons/upload-cloud-01_knrx2h.svg"
                      alt="hasura-hub-icon"
                      minWidth="24px"
                      width="24px"
                      mr="20px"
                      mt="5px"
                    />
                    <Text
                      fontSize="18px"
                      fontWeight="500"
                      lineHeight="1.5"
                      color="#111927"
                      // letterSpacing=""
                    >
                      Create One Click Deploy to Hasura Cloud projects from a publicly available
                      GitHub repo.
                    </Text>
                  </Flex>
                  <Flex alignItems="flex-start" mb="24px">
                    <Image
                      src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675711138/main-web/icons/cli_mziabv.svg"
                      alt="hasura-hub-icon"
                      minWidth="24px"
                      width="24px"
                      mr="20px"
                      mt="5px"
                    />
                    <Text
                      fontSize="18px"
                      fontWeight="500"
                      lineHeight="1.5"
                      color="#111927"
                      // letterSpacing=""
                    >
                      Generate a Hasura CLI project out of your existing app
                    </Text>
                  </Flex>
                  <Flex alignItems="flex-start" mb="24px">
                    <Image
                      src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675711138/main-web/icons/passcode_nmskt5.svg"
                      alt="hasura-hub-icon"
                      minWidth="24px"
                      width="24px"
                      mr="20px"
                      mt="5px"
                    />
                    <Text
                      fontSize="18px"
                      fontWeight="500"
                      lineHeight="1.5"
                      color="#111927"
                      // letterSpacing=""
                    >
                      Add the ENV configurations and descriptions to hasura-cloud-deploy-config.yaml
                    </Text>
                  </Flex>
                  <Flex alignItems="flex-start" mb="24px">
                    <Image
                      src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675711138/main-web/icons/file-plus-02_exsvhk.svg"
                      alt="hasura-hub-icon"
                      minWidth="24px"
                      width="24px"
                      mr="20px"
                      mt="5px"
                    />
                    <Text
                      fontSize="18px"
                      fontWeight="500"
                      lineHeight="1.5"
                      color="#111927"
                      // letterSpacing=""
                    >
                      Embed the Deploy to Hasura Cloud button in your GitHub README with the deploy
                      URL unique to your app
                    </Text>
                  </Flex>
                  <Flex alignItems="flex-start" mb="24px">
                    <Image
                      src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675711138/main-web/icons/text-input_qotcgv.svg"
                      alt="hasura-hub-icon"
                      minWidth="24px"
                      width="24px"
                      mr="20px"
                      mt="2px"
                    />
                    <Text
                      fontSize="18px"
                      fontWeight="500"
                      lineHeight="1.5"
                      color="#111927"
                      // letterSpacing=""
                    >
                      Submit the details of your app in this form
                    </Text>
                  </Flex>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="desk-form-div" mt="-22px">
            <Paperform formId="xwlrnkqm" onSubmitCB={onSubmitCB} styleClass="datahub-form" />
          </Box>
          {/* Mobile View */}
          {!isFormActiveOnMobile && (
            <Flex
              role="button"
              onClick={() => {
                toggleFormView(true);
                scrollToTop();
              }}
              bg="#1E56E3"
              borderRadius="8px"
              minWidth="328px"
              height="55px"
              width="100%"
              justifyContent="center"
              margin="12px auto"
              maxWidth=""
              css={css`
                @media (min-width: 761px) {
                  display: none;
                }
              `}
            >
              <Text fontSize="16px" color="#fff">
                Submit integration
              </Text>
            </Flex>
          )}
        </Flex>
      </StyledContainerWrapper>
    </StyledSectionWrapperBg>
  );
};
